<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box"
			:search="search"
      :option="search_option"

			@click="getSearch"
			@reset="reset"
		>
		</Search>

		<div class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto">

			<div class="justify-end">
				<div>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even"
			>
				<colgroup>
					<col width="60px" />
					<col width="80px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>가맹점명</th>
					<th>배대사명</th>
					<th>계좌 타입</th>
					<th>은행</th>

					<th>계좌번호</th>
					<th>예금주명</th>
					<th>등록일</th>
					<th>첨부서류</th>
					<th>심사</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in items"
					:key="'list_' + index"
				>
					<td>{{ item.idx }}</td>
					<td class="text-left">{{ item.shopName}}</td>
					<td>{{ item.nickname ? item.nickname : '-' }}</td>
					<td>{{ item.type }}</td>
					<td>{{ item.bankName }}</td>

					<td>{{ item.accountNumber }}</td>
					<td>{{ item.depositor }}</td>
					<td>{{ item.createdAt | transDate }}</td>
          <td>
            <button class="pa-5-10 btn-green" style="width: 76px;" @click="download(item.reviewFilePath)">
              미리보기
            </button>
          </td>
					<td>
            <button class="pa-5-10" :class="formState(item.reviewState).color" v-if="item.reviewState" style="width: 76px;" @click="setState(item)">
              {{ formState(item.reviewState).state}}
            </button>
          </td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>

    <Modal
        :is_modal="is_modal_img"
        :option="img_modal_option"
        @close="is_modal_img = false"
    >
      <div slot="modal-content">
        <img :src="item_att" alt="img" style="object-fit: cover; max-width: 600px; max-height: 800px;" />
      </div>
    </Modal>
    <Modal
        :is_modal="is_modal_review"
        :option="review_modal_option"
        :top="true"
        :bottom="true"

        width="550px"
        content_class="ptb-30"

        @close="close"
        @click="doUpdate('DECLINED_REVIEW')"
        @cancel="doUpdate('APPROVED_REVIEW')"
    >
    </Modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Modal from "@/components/Modal";

export default {
	name: 'AccountList',
	components: {Modal, Search, Pagination}
	,data: function(){
		return {
            user: [],
			program: {
				name: '배대사 가상계좌 관리'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,keyword: ''
				,search_type: ''
			}
			,search_option: {
				search_type: [
					{ name: '심사중', column: 'UNDER_REVIEW'}
					, { name: '심사완료', column: 'APPROVED_REVIEW'}
					, { name: '심사반려', column: 'DECLINED_REVIEW'}
				]
			}
			,items: []
			,item: {}
      ,is_modal_review: false
      ,review_modal_option: {
        title: ''
        ,content: ''
        ,click_name: '심사반려'
        ,click_class: 'bg-identify-outline'
        ,cancel_name: '심사완료'
        ,cancel_class: 'btn-success'
        ,top: true
        ,bottom: false
        ,is_click: true
        ,is_cancel: true
      }
      ,is_modal_img: false
      ,img_modal_option: {
        title: '첨부서류'
        ,top: true
        ,bottom: false
      }
      ,item_att: ''
      ,selectedItem: []
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'account'
					,data: {
            page: this.search.page
            ,size: this.search.size
            ,keyword: this.search.keyword
            ,reviewState: this.search.search_type
          }
				})

				if(result.success){
          console.log(result.data.data)
					this.items = result.data.data
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
    , formState(state) {
      let t
      switch (state) {
        case 'UNDER_REVIEW':
          t = {
            state: '심사중',
            color: 'bg-identify-outline'
          }
          break;
        case 'APPROVED_REVIEW':
          t = {
            state: '심사완료',
            color: 'btn-green'
          }
          break;
        case 'DECLINED_REVIEW':
          t = {
            state: '심사반려',
            color: 'btn-red'
          }
          break;
      }
      return t
    }
    ,download: function(path){
      this.is_modal_img = true
      this.item_att = path
    }
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}
    ,close: function () {
      this.is_modal_review = false
      this.selectedItem = []
    }
    ,setState: function (item) {
      this.selectedItem = item
      console.log(item)
      if(item.reviewState === 'UNDER_REVIEW') {
        this.review_modal_option.title = '배대사 가상계좌 심사'
        this.review_modal_option.content = '선택한 배대사 가상계좌를 승인 하시겠습니까?'
        this.review_modal_option.is_click = false
        this.review_modal_option.is_cancel = false
      } else if(item.reviewState === 'APPROVED_REVIEW'){
        this.review_modal_option.title = '배대사 가상계좌 상태 변경'
        this.review_modal_option.content = '선택한 배대사 가상계좌의 상태를 변경 하시겠습니까?'
        this.review_modal_option.is_click = false
        this.review_modal_option.is_cancel = true
      } else {
        this.review_modal_option.title = '배대사 가상계좌 심사'
        this.review_modal_option.content = '선택한 배대사 가상계좌는 반려된 가상계좌입니다. \n승인 처리 하시겠습니까?'
        this.review_modal_option.is_click = true
        this.review_modal_option.is_cancel = false
      }
      this.is_modal_review = true
    }
		,doUpdate: async function(state){
			try{
        if(this.selectedItem.idx){
          this.$layout.onLoading()
          const result = await this.$Axios({
            method: 'put'
            ,url: 'account/' + this.selectedItem.idx
            ,data: {
              state: state
            }
          })
          if(result.success){
            this.$layout.setNotify( { type: 'success', message: result.message})
            await this.getSearch()
          }else{
            this.$layout.setNotify( { type: 'error', message: result.message})
          }
        }
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
				this.close()
			}
		}
		,reset: function(){
			this.search = {
        page: 1
          ,size: 10
          ,keyword: ''
          ,search_type: ''
      }
			this.getData()
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>